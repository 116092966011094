import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopNav from '../../components/top-nav';
import Loading from '../../components/loading';
import _3dSettings from '../../apis/api/_3dSettings';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import Location from '../../components/location';
import FloorPlanGallery from '../../components/floor-plan-gallery';
import BottomNavigation from '../../components/bottom-navigation';
import PrecinctExplore from '../../components/precinct-explore';
import PrecinctSummary from '../../components/precinct-summary';
import TransportOption from '../../components/district-transport-option';
import Gallery from '../../components/gallery';
import Environment from '../../components/environment';
import DistrictFutureTimeline from '../../components/district-future-timeline';
import RightPanel from '../../components/right-panel';

import { reqSetSelectedUnit, reqIsShowGallery, reqIsShowFloorplan, reqSetIsShowPrecinctDetail } from '../../reduxs/unit-explore/action';
import { reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import {
  reqSetIsExpandNav,
  reqSetPage,
  reqSetActiveGalleryId,
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
  reqSetIsShowEndGuideTenantSession,
  reqSetActiveEndGuideTenantSessionId,
} from '../../reduxs/home/action';
import { PAGES, LAYERS } from '../../constants/options';
import { threePosition } from '../../helper/threeHeper';
import Login from '../../components/auth/Login';
import { reqSetIsShowPrecinctExploreDetail, reqSetIsShowReplayVideo } from '../../reduxs/precinct-explore/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';
import { setColor2 } from '../../helper/threeHeper';
import { reqSetIsShowGalleryModal } from '../../reduxs/district-future-detail/action';
import TransportOptionDistrict from '../../components/transport-options-district';
import { ref } from 'yup';
import EndGuideTenantSessionModal from '../../components/guide-sesions/end-guide-tenant-session-modal';
import { actIntroduction, setIsLoading } from '../../reduxs/scene/action';
import { reqSetCustomerProfile } from '../../reduxs/user/action'
import VideoIntro from '../../components/video-intro';
import PostSessionPopup from '../../components/post-session-popup';

const ReactUI = (props) => {
  const { setIsIntroduction, controls, refScene, setActiveObjectIds, resetActiveColorModel, activeObjectIds } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const page = useSelector((state) => state.home.page);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const transportOptionDistricts = useSelector((state) => state.home.transportOptionDistricts);
  const isShowReplayVideo = useSelector((state) => state.precinctExplore.isShowReplayVideo)

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(setting => setting.name == page);
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null || controls.current.currentPage == page) {
      return <div />;
    }
    controls.current.currentPage = page;
    const setting = settings[0];

    setting.active_objects != null && setting.active_objects.length > 0 && setActiveObjectIds(setting.active_objects);

    if (setting.camera && setting.camera_look_at_position) {
      const camPosition = threePosition(setting.camera);
      const camLookAtPosition = threePosition(setting.camera_look_at_position);
      controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, () => { });
    }

    if (Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(0);
      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }
    }
    return <div />
  });
  HandleSetActivePage.displayName = 'HandleSetActivePage';

  const handleMoveCamera = (object, onCompleted = () => { }) => {
    const position = threePosition(object.xyz_position);

    if (object.cam_position != null) {
      const camPosition = threePosition(object.cam_position);
      const camLookAtPosition =
        object.cam_focus_point_position != null
          ? threePosition(object.cam_focus_point_position)
          : position;
      controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, onCompleted);
    }
  };

  const handleClickCube = () => {
    resetState();
    dispatch(reqSetIsExpandNav(!isNavExpand));
  };

  const handleClickGallery = () => {
    resetState();
    dispatch(reqSetActiveGalleryId('6167e68b4441c1a3e5335dcf'))
    dispatch(reqSetPage(PAGES.GALLERY_PAGE));
  };

  const handleClickLocation = () => {
    resetState();
    dispatch(reqSetPage(PAGES.EXPLORE_CITY_PAGE));
  };

  const handleClickEnvironment = () => {
    resetState();
    dispatch(reqSetPage(PAGES.ENVIRONMENT_PAGE));
  };

  const handleClickExploreDistrict = () => {
    resetState();
    dispatch(reqSetPage(PAGES.EXPLORE_DISTRICT_PAGE));
    controls.current.hideLayer(LAYERS.D_HOTSPOT);
    controls.current.showAndEnableLayer(LAYERS.EXPLORE_DISTRICT_HOTSPOT);
  }

  const handleClickExplorePrecinct = () => {
    resetState();
    dispatch(reqSetPage(PAGES.EXPLORE_PRECINCT_PAGE));
  }

  const handleClickEndGuideTenantSession = (customerID) => {
    resetState();
    dispatch(reqSetActiveEndGuideTenantSessionId(customerID));
    dispatch(reqSetIsShowEndGuideTenantSession(true));
    dispatch(reqSetPage(PAGES.END_GUIDE_TENANT_SESSION_PAGE));
  }

  const handleClickTenantEndSession = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push('/');
    // dispatch(setIsLoading({ isLoading: true }));
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(''));
  }

  const handleTimelineYearChanged = (year) => {
    if (refScene.current == null) {
      return;
    }
    let layer = year - 2010;

    if (layer < LAYERS.BUILDING_23 - 2 || layer > LAYERS.BUILDING_31) {
      return;
    }

    for (let i = LAYERS.BUILDING_23 - 1; i <= LAYERS.BUILDING_31; i += 1) {
      if (i <= layer) {
        controls.current?.showAndEnableLayer(i);
      } else {
        controls.current?.hideLayer(i);
      }
    }
  };

  const handleClickTransportOptions = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const handleClickTransportOptionDistricts = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const resetState = () => {

    handleClickTransportOptions([], {});

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;

    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach(id => {
          let object = refScene.current.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        })
      });
    }

    resetActiveColorModel({});
    setActiveObjectIds([]);
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetSelectedUnit(''));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
    dispatch(reqSetIsShowPrecinctDetail(false));
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetActivePrecinctID(null));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
  };

  const renderFutureTimeline = () => {
    if (pagesSettings == null) {
      return <div />;
    }
    const settings = pagesSettings.filter(setting => setting.name == page);
    if (settings.length == 0) {
      return <div />;
    }
    const setting = settings[0];
    if (setting.features == null || !setting.features.show_hide_future) {
      return <div />;
    }
    return <TransitionGroup>
      {(
        <CSSTransition timeout={1000} classNames="fade-item">
          <DistrictFutureTimeline handleTimelineYearChanged={handleTimelineYearChanged} />
        </CSSTransition>
      )}
    </TransitionGroup>
  };

  const renderTransportOptions = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter(setting => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (setting.features == null || !setting.features.transport_options) {
      return <div />;
    }

    return <TransitionGroup>
      {(
        <CSSTransition timeout={1000} classNames="fade-item">
          <TransportOption activeObjectIds={activeObjectIds} handleClickTransportOptions={handleClickTransportOptions} />
        </CSSTransition>
      )}
    </TransitionGroup>
  };

  const [isShowBottomNav, showBottomNav] = useState(true);

  const renderTransportOptionDistricts = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter(setting => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (setting.features == null || !setting.features.transport_option_districts) {
      return <div />;
    }

    return <TransitionGroup>
      {(
        <CSSTransition timeout={1000} classNames="fade-item">
          <TransportOptionDistrict activeObjectIds={activeObjectIds} refScene={refScene} handleClickTransportOptionDistricts={handleClickTransportOptionDistricts} />
        </CSSTransition>
      )}
    </TransitionGroup>
  };

  const onReplayVideo = () => {
    dispatch(reqSetIsShowReplayVideo(true));
  }

  return (
    <>
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      <TransitionGroup>
        {page == PAGES.END_GUIDE_TENANT_SESSION_PAGE && (
          <CSSTransition
            timeout={500}
            classNames="fade-item"
            unmountOnExit
          >
            <EndGuideTenantSessionModal resetState={resetState} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TopNav
        isShow={page !== PAGES.ONBOARD_PAGE}
        isLoading={isLoading}
        handleClickCube={handleClickCube}
        handleClickGallery={handleClickGallery}
        handleClickLocation={handleClickLocation}
        handleClickEnvironment={handleClickEnvironment}
        handleClickExploreDistrict={handleClickExploreDistrict}
        handleClickExplorePrecinct={handleClickExplorePrecinct}
      />
      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}
      <TransitionGroup>
        {page == PAGES.LOCATION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Location />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowFloorplan && (
          <CSSTransition in={isShowFloorplan} timeout={1000} classNames="fade-item" unmountOnExit>
            <FloorPlanGallery />
          </CSSTransition>)
        }
      </TransitionGroup>

      <CSSTransition in={page !== PAGES.ONBOARD_PAGE} timeout={1000} classNames="fade-item" unmountOnExit>
        <div className={`d-flex align-items-center position-absolute wrap-bottom-titles ${isShowBottomNav && 'mb-5'}`}>
          <div className="left">
            {
              page == PAGES.LANDING_PAGE &&
              <span onClick={onReplayVideo} className="underline-sm text-uppercase fw-bold btn-replay" role="button">Replay Video</span>
            }
            {(page == PAGES.EXPLORE_DISTRICT_PAGE) &&
              <>
                <h4 className="text-uppercase fw-bold mb-n1">THE DISTRICT</h4>
                <small className="text-uppercase">DOCKLANDS</small>
              </>
            }
            {page === PAGES.EXPLORE_CITY_PAGE &&
              <>
                <span className={`svg-icon mb-3`}>
                  <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H26C26.5304 2 27.0391 2.21071 27.4142 2.58579C27.7893 2.96086 28 3.46957 28 4V11.168V18C28 18.5304 27.7893 19.0391 27.4142 19.4142C27.0391 19.7893 26.5304 20 26 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V11.168V4C2 3.46957 2.21071 2.96086 2.58579 2.58579ZM1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4V18C0 19.0609 0.421427 20.0783 1.17157 20.8284C1.92172 21.5786 2.93913 22 4 22H26C27.0609 22 28.0783 21.5786 28.8284 20.8284C29.5786 20.0783 30 19.0609 30 18V4C30 2.93913 29.5786 1.92172 28.8284 1.17157C28.0783 0.421427 27.0609 0 26 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157ZM22.6784 14.8464C23.6539 13.8708 24.202 12.5477 24.202 11.168C24.202 9.78834 23.6539 8.46519 22.6784 7.48963C21.7028 6.51407 20.3797 5.966 19 5.966C17.6203 5.966 16.2972 6.51407 15.3216 7.48963C14.3461 8.46519 13.798 9.78834 13.798 11.168C13.798 12.5477 14.3461 13.8708 15.3216 14.8464C16.2972 15.8219 17.6203 16.37 19 16.37C20.3797 16.37 21.7028 15.8219 22.6784 14.8464ZM24.0926 16.2606C25.4432 14.9099 26.202 13.0781 26.202 11.168C26.202 9.25791 25.4432 7.42605 24.0926 6.07542C22.7419 4.72478 20.9101 3.966 19 3.966C17.0899 3.966 15.2581 4.72478 13.9074 6.07542C12.5568 7.42605 11.798 9.25791 11.798 11.168C11.798 13.0781 12.5568 14.9099 13.9074 16.2606C15.2581 17.6112 17.0899 18.37 19 18.37C20.9101 18.37 22.7419 17.6112 24.0926 16.2606Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V11.168H11.798C11.798 9.25791 12.5568 7.42605 13.9074 6.07542C15.2581 4.72478 17.0899 3.966 19 3.966C20.9101 3.966 22.7419 4.72478 24.0926 6.07542C25.4432 7.42605 26.202 9.25791 26.202 11.168H28V4C28 3.46957 27.7893 2.96086 27.4142 2.58579C27.0391 2.21071 26.5304 2 26 2H4ZM4.14645 4.14645C4.05268 4.24021 4 4.36739 4 4.5V7.51C4 7.64261 4.05268 7.76978 4.14645 7.86355C4.24021 7.95732 4.36739 8.01 4.5 8.01H9.5C9.63261 8.01 9.75979 7.95732 9.85355 7.86355C9.94732 7.76978 10 7.64261 10 7.51V4.5C10 4.36739 9.94732 4.24021 9.85355 4.14645C9.75979 4.05268 9.63261 4 9.5 4H4.5C4.36739 4 4.24021 4.05268 4.14645 4.14645Z" fill="white" />
                  </svg>
                </span>
                <h4 className="text-uppercase fw-bold mb-n1">MELBOURNE</h4>
                <small className="text-uppercase">VICTORIA</small>
              </>
            }
            {page === PAGES.EXPLORE_PRECINCT_PAGE &&
              <>
                <h4 className="text-uppercase fw-bold mb-n1">DOCKLANDS</h4>
                <small className="text-uppercase">MELBOURNE</small>
              </>
            }
          </div>
          {(page !== PAGES.EXPLORE_CITY_PAGE && page !== PAGES.EXPLORE_PRECINCT_PAGE) &&
            <div className="ms-auto">
              <h4 className="text-uppercase fw-bold mb-0" onClick={handleClickLocation} role="button">Explore the city</h4>
            </div>
          }
          {page === PAGES.EXPLORE_PRECINCT_PAGE && <div className="ms-auto">{renderFutureTimeline()}</div>}
        </div>
      </CSSTransition>

      <TransitionGroup>
        {isShowBottomNav && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <BottomNavigation
              handleClickEndGuideTenantSession={handleClickEndGuideTenantSession}
              handleClickTenantEndSession={handleClickTenantEndSession}
            />
          </CSSTransition>
        )}
      </TransitionGroup>

      {renderTransportOptions()}
      {renderTransportOptionDistricts()}

      <PrecinctExplore />

      <TransitionGroup>
        {page == PAGES.PRECINCT_SUMMANRY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <PrecinctSummary />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition
            timeout={500}
            classNames="fade-item"
            unmountOnExit
          >
            <Gallery />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {page == PAGES.ENVIRONMENT_PAGE && (
          <CSSTransition
            timeout={500}
            classNames="fade-item"
            unmountOnExit
          >
            <Environment />
          </CSSTransition>
        )}
      </TransitionGroup>

      <RightPanel page={page} handleClickTransportOptions={handleClickTransportOptions} />
      <Login />
      <TransitionGroup>
        { isShowReplayVideo &&
          <CSSTransition
            timeout={500}
            classNames="fade-item"
            unmountOnExit
          >
            <VideoIntro />
          </CSSTransition>
        }

      </TransitionGroup>
      <PostSessionPopup/>
    </>
  );
};

export default ReactUI;

