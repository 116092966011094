import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
  reqSetIsShowUserTypeModal,
  reqSetUserSelectTypeModal,
  reqSetIsShowListConsultantModal
} from "../../reduxs/guide-session/action";
import "./index.scss";

const UserTypeModal = () => {
  const dispatch = useDispatch();
  const isShowUserTypeModal = useSelector(
    (state) => state.guideSession.isShowUserTypeModal
  );

  const handleClose = () => {
    dispatch(reqSetIsShowUserTypeModal(false));
  };

  const showListConsultantModal = () => {
    dispatch(reqSetIsShowListConsultantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("consultant"));
  };

  const showListInvestorModal = () => {
    dispatch(reqSetIsShowListInvestorModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("investor"));
  };

  const showListTenantModal = () => {
    dispatch(reqSetIsShowListTenantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("tenant"));
  };

  return (
    <>
      <Modal
        className="wrap-user-type-modal"
        show={isShowUserTypeModal}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Body className="wrap-modal-body">
          <div className="modal-form__title" style={{fontSize : '25px'}}>
            SET UP A GUIDED SESSION USING THE
          </div>
          <div className="modal-form__title underline-sm mb-4" style={{fontSize : '25px'}}>
            DISTRICT DOCKLANDS EXPERIENCE APP
          </div>
          <p className="mb-5" style={{fontSize : '18px'}}>Select the user type for today&apos;s session</p>
          <div className="d-flex justify-content-between">
            <button onClick={showListConsultantModal} style={{fontSize : '15px'}} className="btn btn-outline text-uppercase">
              <strong>Consultant</strong>
            </button>
            <button onClick={showListInvestorModal} style={{fontSize : '15px'}} className="btn btn-outline text-uppercase">
              <strong>Investor</strong>
            </button>
            <button onClick={showListTenantModal} style={{fontSize : '15px'}} className="btn btn-outline text-uppercase">
            <strong>Tenant</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserTypeModal;
