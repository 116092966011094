import React, { useEffect, useState } from "react";
import { Modal, InputGroup, FormControl, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./index.scss";
import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
} from "../../reduxs/guide-session/action";

import customerAPi from "../../apis/api/customer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ListUserSelectModal = (props) => {
  const { startDiscover } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [listUserSelect, setListUserSelect] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [searchInput, setSearchInput] = useState("");
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListTenantModal = useSelector(
    (state) => state.guideSession.isShowListTenantModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  useEffect(() => {
    if (isShowListInvestorModal) {
      getListInvestor();
    }
    if (isShowListTenantModal) {
      getListTenant();
    }
    if (isShowListConsultantModal) {
      getListConsultant();
    }
  }, [isShowListInvestorModal, isShowListTenantModal, isShowListConsultantModal]);

  const getListInvestor = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const getListTenant = async () => {
    const result = await customerAPi.getTenantList();
    setListUserSelect(result?.data || []);
  };

  const getListConsultant = async () => {
    const result = await customerAPi.getConsultantList();
    setListUserSelect(result?.data || []);
  };

  const onSelectUser = (user) => {
    history.push({
      search: `?customer=${user?.id}`,
    });
    setSelectedUser(user);
  };

  const isShowCreateUserProfileModal = () => {
    history.push({
      search: "",
    });
    if (userSelectType === "investor") {
      dispatch(reqSetIsShowCreateInvestorProfileModal(true));
      dispatch(reqSetIsShowListInvestorModal(false));
    } else if(userSelectType === 'tenant') {
      dispatch(reqSetIsShowCreateTenantProfileModal(true));
      dispatch(reqSetIsShowListTenantModal(false));
    } else if (userSelectType === 'consultant') {
      dispatch(reqSetIsShowCreateConsultantProfileModal(true));
      dispatch(reqSetIsShowListConsultantModal(false));
    }
  };

  const handleClose = () => {
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));
  };

  const getActiveClass = (user) => {
    return selectedUser?.id === user?.id
      ? "cusor-pointer active"
      : "cursor-pointer";
  };

  const onStartUserSession = () => {
    if (!selectedUser) return toast.error("Please select a user");

    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));

    return startDiscover();
  };

  return (
    <>
      <Modal
        className="wrap-list-user-modal"
        show={isShowListTenantModal || isShowListInvestorModal || isShowListConsultantModal}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="wrap-modal-body">
          <div className="modal-form__title">
            SET UP A GUIDED SESSION USING THE
          </div>
          <div className="modal-form__title underline-sm mb-4">
            DISTRICT DOCKLANDS EXPERIENCE APP
          </div>
          <p className="mb-4 modal-form__sub-title">
            Select the {userSelectType} for today&apos;s session
          </p>
          <div className="d-flex mb-4">
            <InputGroup className="form-group">
              <FormControl
                className="form-control"
                placeholder="Search..."
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <span role="button">
                <img src="/icons/next-arrow.svg" />
              </span>
            </InputGroup>
          </div>
          <div className="wrap-user-list">
            {listUserSelect
              ?.filter((user) => {
                if (searchInput == "") {
                  return user;
                } else if (
                  user?.name
                    .toLowerCase()
                    .includes(searchInput.toLocaleLowerCase())
                ) {
                  return user;
                }
              })
              .map((user) => {
                return (
                  <p
                    className={getActiveClass(user)}
                    key={user.id}
                    tabIndex={-1}
                    onClick={() => onSelectUser(user)}
                  >
                    {user?.name}
                  </p>
                );
              })}
          </div>
          <div className="wrap-button d-flex justify-content-between">
            <button onClick={isShowCreateUserProfileModal}>
              create new user
            </button>
            <button onClick={() => onStartUserSession()}>select user</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListUserSelectModal;
