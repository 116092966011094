import React, { useState } from 'react';
import './index.scss';

const DistrictFutureTimeline = (props) => {
  const {handleTimelineYearChanged} = props;
  const [districtYear, setDistrictYear] = useState(2021);
  const [isCollapsed, setCollapse] = useState(false);

  const setActiveDistrictYear = (year) => {
    setDistrictYear(year);
    handleTimelineYearChanged(year);
  }

  const changeActiveYear = (e) => {
    setActiveDistrictYear(e.target.value);
  }

  return (
    <div className={`timeline ${isCollapsed && 'collapsed'}`}>
      <div className="d-flex align-items-start">
        <div className="timeline__wrapper position-relative ms-auto me-4">
          <input
            type="range"
            className="form-range"
            min="2021"
            max="2031"
            step="2"
            id="rangeTimeline"
            value={districtYear}
            onChangeCapture={(e) => changeActiveYear(e)} />
          
          <div className="d-flex timeline__milestone-group justify-content-between">           
            <span onClick={() => setActiveDistrictYear(2021)} className={`timeline__milestone-item`}>2021</span>
            <span onClick={() => setActiveDistrictYear(2023)} className={`timeline__milestone-item`}>2023</span>
            <span onClick={() => setActiveDistrictYear(2025)} className={`timeline__milestone-item`}>2025</span>
            <span onClick={() => setActiveDistrictYear(2027)} className={`timeline__milestone-item`}>2027</span>
            <span onClick={() => setActiveDistrictYear(2029)} className={`timeline__milestone-item`}>2029</span>
            <span onClick={() => setActiveDistrictYear(2031)} className={`timeline__milestone-item`}>2031+</span>
          </div>
        </div>
        <div className="timeline__control ms-4 mt-1">
          <button onClick={() => setCollapse(!isCollapsed)} className="btn btn-icon btn-green rounded-circle">
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default DistrictFutureTimeline;
