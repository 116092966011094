import React from 'react';
import './index.scss';

const Environment = () => {
  return (
    <div className="wrap-environment-page">
      <div className="container-fluid d-flex h-100">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-4 col-md-4 col-sm-5">
            <div className="wrap-env-info">
              <h4 className="title">ENVIRONMENTAL INFORMATION</h4>
              <p className="description">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. A nobis impedit hic
                debitis eligendi atque maiores magni ea, accusamus dolorem laborum quae harum
                architecto voluptatem beatae est porro modi repellat!
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-7">
            <div className="wrap-site-info">
              <p className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores porro earum repudiandae numquam corrupti inventore eaque, reiciendis fuga ut aperiam a sunt, sit autem iusto. Saepe sunt tempora quod ab?</p>
            </div>
            <div className="wrap-site-description">
              <p className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores porro earum repudiandae numquam corrupti inventore eaque, reiciendis fuga ut aperiam a sunt, sit autem iusto. Saepe sunt tempora quod ab?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Environment;
