import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actSetEditablePropertyColumn } from '../../reduxs/cms/action';
import { toDateFormat, toDateString } from "../../helper/date";
import { toast } from "react-toastify";

const PropertyRow = (props) => {
  const { data, editType, isEdit } = props;
  const dispatch = useDispatch();
  const { tenancy, availabilityStatus, psm, expiryDate, tradingName, level, precinct, areaSqm } = data;
  const editablePropertyColumn = useSelector((state) => state.cms.editablePropertyColumn);
  const [levelInput, setLevelInput] = useState(data?.level);
  const [precinctInput, setPrecinctInput] = useState(data?.precinct);
  const [tenancyInput, setTenancyInput] = useState(data?.tenancy);
  const [areaSqmInput, setAreaSqmInput] = useState(data?.areaSqm);
  const [availabilityInput, setAvailabilityInput] = useState(data?.availabilityStatus);
  const [PSMInput, setPSMInput] = useState(data?.psm);
  const [tradingNameInput, setTradingNameInput] = useState(data?.tradingName);
  const [expiryDateInput, setExpiryDateInput] = useState(data?.expiryDate ? data?.expiryDate : "");

  const handleWarningSave = (e) => {
    console.log(editablePropertyColumn, data);
    if (editablePropertyColumn && (editablePropertyColumn?.id !== data?.id)) {
      toast.warning('Please save before editing another property.');
      e.target.blur();
    }
  }

  useEffect(() => {
    if (!isEdit &&
      (tenancy !== tenancyInput ||
        availabilityStatus !== availabilityInput ||
        psm !== PSMInput ||
        expiryDate !== expiryDateInput
        || tradingName !== tradingNameInput ||
        level !== levelInput || 
        precinct !== precinctInput || 
        areaSqmInput != areaSqm
      )) {
      setLevelInput(data?.level);
      setPrecinctInput(data?.precinct);
      setTenancyInput(data?.tenancy);
      setAreaSqmInput(data?.areaSqm);
      setAvailabilityInput(data?.availabilityStatus);
      setPSMInput(data?.psm);
      setExpiryDateInput(data?.expiryDate);
      setTradingNameInput(data?.tradingName);
    }
  }, [isEdit, areaSqmInput, areaSqm, precinct, precinctInput, level, levelInput, tenancy, tenancyInput, availabilityStatus, availabilityInput, psm, PSMInput, expiryDate, expiryDateInput, tradingName, tradingNameInput]);

  useEffect(() => {
    if (tenancy !== tenancyInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        tenancy: tenancyInput,
      }))
    }
  }, [tenancy, tenancyInput])

  useEffect(() => {
    if (level !== levelInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        level: levelInput,
      }))
    }
  }, [level, levelInput]);

  useEffect(() => {
    if (precinct !== precinctInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        precinct: precinctInput,
      }))
    }
  }, [precinct, precinctInput]);

  useEffect(() => {
    if (areaSqm !== areaSqmInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        areaSqm: areaSqmInput,
      }))
    }
  }, [areaSqm, areaSqmInput]);

  useEffect(() => {
    if (availabilityStatus !== availabilityInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        availabilityStatus: availabilityInput,
      }))
    }
  }, [availabilityStatus, availabilityInput])

  useEffect(() => {
    if (psm !== Number(PSMInput)) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        psm: PSMInput,
      }))
    }
  }, [psm, PSMInput])

  useEffect(() => {
    if (tradingName !== tradingNameInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        tradingName: tradingNameInput,
      }))
    }
  }, [tradingName, tradingNameInput])

  useEffect(() => {
    if (expiryDateInput) {
      const toISOExpiryDateInput = new Date(expiryDateInput).toISOString();
      if (expiryDate !== toISOExpiryDateInput) {
        dispatch(actSetEditablePropertyColumn({
          id: data.id,
          expiryDate: toISOExpiryDateInput,
        }))
      }
    }
  }, [expiryDate, expiryDateInput])

  return (
    <tr
      className="border-0"
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }}
    >
      <td className="col-1">
        {editType !== "level" ? (
          levelInput
        ) : (
          <select
            style={{ padding: "2px 2px", fontSize: '18px' }}
            value={levelInput}
            onChange={(e) => setLevelInput(e.target.value)}
            onClick={handleWarningSave}
            className="custom-input text-capitalize"
          >
            <option className="text-capitalize" value="GROUND">GROUND</option>
            <option className="text-capitalize" value="LEVEL ONE">LEVEL ONE</option>
          </select>
        )}
      </td>
      <td>
        {editType !== "tenancy" ? (
          tenancyInput
        ) : (
          <input
            value={tenancyInput}
            className="custom-input"
            onChange={(e) => setTenancyInput(e.target.value)}
            onClick={handleWarningSave}
          />
        )}
      </td>
      <td className="col-2">{
        editType !== 'tradingName'
          ? tradingNameInput
          : ( <input
            value={tradingNameInput}
            onChange={(e) => setTradingNameInput(e.target.value)}
            className="custom-input"
            onClick={handleWarningSave}
            type="text"
          />)
      }</td>
      <td className="text-capitalize">
        {editType !== "availability" ? (
          availabilityInput
        ) : (
          <select
            style={{ padding: "2px 2px", fontSize: '18px' }}
            value={availabilityInput}
            onChange={(e) => setAvailabilityInput(e.target.value)}
            onClick={handleWarningSave}
            className="custom-input text-capitalize"
          >
            <option className="text-capitalize" value="value">{data?.availabilityStatus ? data?.availabilityStatus : "value"}</option>
          </select>
        )}
      </td>
      <td className="col-2">
        {editType !== "precinct" ? (
          precinctInput
        ) : (
          <select
            style={{ padding: "2px 2px", fontSize: '18px' }}
            value={precinctInput}
            onChange={(e) => setPrecinctInput(e.target.value)}
            onClick={handleWarningSave}
            className="custom-input text-capitalize"
          >
            <option className="text-capitalize" value="Entertainment and F&B">Entertainment and F&B</option>
            <option className="text-capitalize" value="General Retail">General Retail</option>
            <option className="text-capitalize" value="Health & Wellbeing">Health & Wellbeing</option>
            <option className="text-capitalize" value="Lifestyle & Tourism">Lifestyle & Tourism</option>
            <option className="text-capitalize" value="Commercial Office">Commercial Office</option>
            <option className="text-capitalize" value="Fresh Food and Daily Needs">Fresh Food and Daily Needs</option>
          </select>
        )}  
      </td>
      <td className="col-1">
        {editType !== "area sqm" ? (
          areaSqmInput
        ) : (
          <input
            value={areaSqmInput}
            onChange={(e) => setAreaSqmInput(e.target.value)}
            className="custom-input"
            onClick={handleWarningSave}
            type="number"
          />
        )}
      </td>
      <td className="col-1">
        {editType !== "psm" ? (
          PSMInput
        ) : (
          <input
            value={PSMInput}
            onChange={(e) => setPSMInput(e.target.value)}
            className="custom-input"
            onClick={handleWarningSave}
            type="number"
          />
        )}
      </td>
      <td className="col-2">
        {editType !== "expiry" ? (
          toDateString(expiryDateInput)
        ) : (
          <>
            <input
              type="date"
              defaultValue={toDateFormat(expiryDateInput)}
              onChange={(e) => setExpiryDateInput(e.target.value)}
              className="custom-input"
              min="2020-01-01"
              onClick={handleWarningSave}
              style={{ cursor: 'text' }}
            />
          </>
        )}
      </td>
    </tr>
  );
}

export default PropertyRow;
