import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { USER_GROUP } from '../../constants/master-data';
import { GROSS_RENT_OPTION, LEVEL_OPTION, AREA_OPTION, PRECINCT_OPTION } from '../../constants/options';
import * as unitExploreAct from '../../reduxs/unit-explore/action';

const PrecinctFilters = () => {
  const dispatch = useDispatch();

  const filterUnitArea = useSelector((state) => state.unitExplore.filterUnitArea);
  const filterUnitGrossRent = useSelector((state) => state.unitExplore.filterUnitGrossRent);
  const filterUnitCentre = useSelector((state) => state.unitExplore.filterUnitCentre);
  const filterUnitLevel = useSelector((state) => state.unitExplore.filterUnitLevel);
  const filterUnitPrecinct = useSelector((state) => state.unitExplore.filterUnitPrecinct);

  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);

  const onFilterLevel = (item) => {
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const level = filterUnitLevel == item.value ? '' : item.value;
    dispatch(unitExploreAct.reqFilterUnitLevel(level));
  };

  const onFilterArea = (item) => {
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const area = filterUnitArea == item.id ? '' : item.id;
    dispatch(unitExploreAct.reqFilterUnitArea(area));
  };

  const onFilterGrossRent = (item) => {
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const grossRent = filterUnitGrossRent == item.id ? '' : item.id;
    dispatch(unitExploreAct.reqFilterUnitGrossRent(grossRent));
  };

  const onFilterPrecinct = (item) => {
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    const precinct = filterUnitPrecinct == item.value ? '' : item.value;
    dispatch(unitExploreAct.reqFilterUnitPrecinct(precinct));
  };

  const renderTitle = () => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT || [USER_GROUP.CONSULTANT, USER_GROUP.TENANT].includes(authUser?.userGroup?.name)) {
      return (<>FILTERS</>);
    }

    return (<>INVESTOR<br />FILTERS</>)
  }

  const renderGrossRentFilter = () => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT || [USER_GROUP.CONSULTANT, USER_GROUP.TENANT].includes(authUser?.userGroup?.name)) {
      return;
    }

    return (
      <ul className="list-group mb-5 mx-n3" key={`filter-group-gross-rent`}>
        <li className="list-group-item list-group-title">GROSS RENT</li>
        {
          GROSS_RENT_OPTION.map((filter, index) => (
            <li
              onClick={() => onFilterGrossRent(filter)}
              className={`list-group-item ${filter.id == filterUnitGrossRent ? 'active' : ''}`} key={`filter-group-gross-rent-${index}`}>
              <span>{filter.text}</span>
            </li>
          ))
        }
      </ul>
    )
  }

  return (
    <div id="precint-filter" className="card">
      <div className="card-header">
        <h2 className="card-title">
          {renderTitle()}
        </h2>
      </div>
      <div className="card-body pt-0">
        {renderGrossRentFilter()}
        <ul className="list-group mb-5 mx-n3" key={`filter-group-centre`}>
          <li className="list-group-item list-group-title">PRECINCT</li>
          {
            PRECINCT_OPTION.map((filter, index) => (
              <li
                onClick={() => onFilterPrecinct(filter)}
                className={`list-group-item ${filter.value == filterUnitPrecinct ? 'active' : ''}`} key={`filter-group-precinct-${index}`}>
                <span>{filter.text}</span>
              </li>
            ))
          }
        </ul>
        <ul className="list-group mb-5 mx-n3" key={`filter-group-level`}>
          <li className="list-group-item list-group-title">LEVEL</li>
          {
            LEVEL_OPTION.map((filter, index) => (
              <li
                onClick={() => onFilterLevel(filter)}
                className={`list-group-item ${filter.value == filterUnitLevel ? 'active' : ''}`} key={`filter-group-level-${index}`}>
                <span>{filter.text}</span>
              </li>
            ))
          }
        </ul>
        <ul className="list-group mb-5 mx-n3" key={`filter-group-area`}>
          <li className="list-group-item list-group-title">AREA</li>
          {
            AREA_OPTION.map((filter, index) => (
              <li
                onClick={() => onFilterArea(filter)}
                className={`list-group-item ${filter.id == filterUnitArea ? 'active' : ''}`} key={`filter-group-area-${index}`}>
                <span>{filter.text}</span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
};
export default PrecinctFilters;
