import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { PAGES } from '../../constants/options';
import { reqSetPage } from '../../reduxs/home/action';
import { reqIsShowFloorplan, reqSetIsShowPrecinctDetail } from '../../reduxs/unit-explore/action';
import transformHelper from '../../helper/transform';
import { reqSetIsShowPrecinctExploreDetail } from '../../reduxs/precinct-explore/action';

const PrecinctDetail = (props) => {

  const dispatch = useDispatch();
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);

  const onShowPrecinctSummary = () => {
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
    dispatch(reqSetPage(PAGES.PRECINCT_SUMMANRY_PAGE));
  }

  const onClosePrecinctDetail = () => {
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowPrecinctDetail(false));
  }

  const renderFloorplanBtn = () => {
    if (isShowFloorplan) {
      return <button onClick={() => dispatch(reqIsShowFloorplan(false))} className="btn btn-outline mb-2">CLOSE FLOORPLAN</button>;
    }

    return <button onClick={() => dispatch(reqIsShowFloorplan(true))} className="btn btn-outline mb-2">VIEW FLOORPLAN</button>;
  }

  return (
    <div id="precint-info" className="card h-100">
      <div className="card-header d-flex">
        <h2 className="card-title mb-0 underline-sm">
          {selectedUnit?.tenancy} INVESTMENT DETAILS
        </h2>
        <span className="close" role="button" onClick={() => onClosePrecinctDetail() }>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM14.707 8.707C14.8892 8.5184 14.99 8.2658 14.9877 8.0036C14.9854 7.7414 14.8802 7.49059 14.6948 7.30518C14.5094 7.11977 14.2586 7.0146 13.9964 7.01233C13.7342 7.01005 13.4816 7.11084 13.293 7.293L11 9.586L8.707 7.293C8.61475 7.19749 8.50441 7.12131 8.3824 7.0689C8.2604 7.01649 8.12918 6.9889 7.9964 6.98775C7.86362 6.9866 7.73194 7.0119 7.60905 7.06218C7.48615 7.11246 7.3745 7.18671 7.28061 7.28061C7.18671 7.3745 7.11246 7.48615 7.06218 7.60905C7.0119 7.73194 6.9866 7.86362 6.98775 7.9964C6.9889 8.12918 7.01649 8.2604 7.0689 8.3824C7.12131 8.50441 7.19749 8.61475 7.293 8.707L9.586 11L7.293 13.293C7.19749 13.3852 7.12131 13.4956 7.0689 13.6176C7.01649 13.7396 6.9889 13.8708 6.98775 14.0036C6.9866 14.1364 7.0119 14.2681 7.06218 14.391C7.11246 14.5139 7.18671 14.6255 7.28061 14.7194C7.3745 14.8133 7.48615 14.8875 7.60905 14.9378C7.73194 14.9881 7.86362 15.0134 7.9964 15.0123C8.12918 15.0111 8.2604 14.9835 8.3824 14.9311C8.50441 14.8787 8.61475 14.8025 8.707 14.707L11 12.414L13.293 14.707C13.4816 14.8892 13.7342 14.99 13.9964 14.9877C14.2586 14.9854 14.5094 14.8802 14.6948 14.6948C14.8802 14.5094 14.9854 14.2586 14.9877 13.9964C14.99 13.7342 14.8892 13.4816 14.707 13.293L12.414 11L14.707 8.707Z" fill="black"/>
          </svg>
        </span>
      </div>
      <div className="card-body">
        {!!selectedUnit?.psm && <p>GROSS RENT : ${transformHelper.formatMoney(selectedUnit?.psm)} PSM</p>}
        <p>CENTRE : {selectedUnit?.centre}</p>
        <p>LEVEL : {selectedUnit?.level}</p>
        <p>AREA : {selectedUnit?.areaSqm} sqm</p>
      </div>
      <div className="card-footer">
        {renderFloorplanBtn()}
        {/* <button onClick={() => onShowPrecinctSummary()} className="btn btn-solid">VIEW SUMMARY</button> */}
      </div>
    </div>
  )
};
export default PrecinctDetail;
