import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMediaUrl } from '../../helper/media';
import { PAGES } from '../../constants/options';
import galleryApi from '../../apis/api/gallery';
import { useDispatch } from 'react-redux';
import { reqSetActivePatourId, reqSetPage } from '../../reduxs/home/action';
import { useSelector } from 'react-redux';

const Gallery = (props) => {
  const [gallery, setGallery] = useState([]);
  const [media, setMedia] = useState([]);
  const dispatch = useDispatch();
  const galleryRef = useRef();
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const page = useSelector((state) => state.home.page);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: 'slides',
    arrows: false,
    fade: true,
    lazyLoad: true,
  };

  const HOTSPOT_TYPES = {
    PANO_TOUR: '360_pano_tour',
    URL: 'url',
    VIDEO: 'video',
  };

  useEffect(async () => {
    const res = await galleryApi.getGalleryDetail(activeGalleryId);
    setGallery(res.data);
  }, [activeGalleryId]);

  useEffect(async () => {
    setMedia(gallery?.media ? gallery.media : []);
  }, [gallery]);

  const handleClickHotspot = async (data) => {
    const hotpotData = data;

    if (hotpotData.link_type == HOTSPOT_TYPES.PANO_TOUR) {
      dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
      dispatch(reqSetActivePatourId(hotpotData.link))
    }
  };

  return (
    <>
      <div className="wrap-gallery-image">
        <Slider ref={galleryRef} {...settings}>
          {media.map((item, key) => {
            return (
              <div key={key}>
                <div
                  className="gallery-item"
                  style={{ backgroundImage: `url(${getMediaUrl(item.path)})` }}
                >
                  {item.hotspots.map((item, index) => {
                    return (
                      <img
                        onClick={() => handleClickHotspot(item)}
                        key={index}
                        style={{
                          left: `${item.position.x}%`,
                          top: `${item.position.y}%`,
                        }}
                        className="gallery-hotspot"
                        src={getMediaUrl(item.image_path)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
        {
          PAGES.GALLERY_PAGE === page &&
          <span className="btn-gallery prev-slide">
            <img width="30" className="img-fluid" src="/icons/left-arrow.png" alt="" onClick={() => galleryRef.current.slickPrev()} />
          </span>
        }
        {
          PAGES.GALLERY_PAGE === page &&
          <span className="btn-gallery next-slide">
            <img width="30" className="img-fluid" src="/icons/right-arrow.png" alt="" onClick={() => galleryRef.current.slickNext()} />
        </span>
        }
      </div>
    </>
  );
};

export default Gallery;
