import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import dayjs from 'dayjs';
import transformHelper from '../../helper/transform';
import { reqGetCustomerPreference, reqSetIsShowPrecinctExploreDetail } from '../../reduxs/precinct-explore/action';
import { USER_GROUP } from '../../constants/master-data';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ListPrecinct = (props) => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.unitExplore.units);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const customer = useSelector((state) => state.user.customer);
  const preference = useSelector((state) => state.precinctExplore.customerPreference);
  const customerId = useQuery().get('customer');
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    if (customerId) {
      dispatch(reqGetCustomerPreference(customerId));
    }
  }, [customerId]);

  const onSelectUnit = (unitId) => {
    dispatch(unitExploreAct.reqSetSelectedUnit(unitId));
    dispatch(unitExploreAct.reqSetIsShowPrecinctDetail(true));
  }

  const onClosePrecinct = () => {
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
  }

  const formatExpiryDate = (date) => {
    if (!date) return;

    return dayjs(date).format('DD/MM/YYYY');
  }

  const getUnitIds = () => {
    const units = preference?.units || [];

    return units.map(item => item.id);
  }

  const renderPsmHeader = () => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT || authUser?.userGroup?.name === USER_GROUP.CONSULTANT) return;

    return (
      <th>$ PSM</th>
    );
  }

  const renderExpiredDateHeader = () => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT || authUser?.userGroup?.name === USER_GROUP.CONSULTANT) return;

    return (
      <th>EXPIRY</th>
    )
  }

  const renderLikedHeader = () => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT) {
      return (
        <th>LIKED</th>
      )
    };
  }

  const renderPsmData = (item) => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT || authUser?.userGroup?.name === USER_GROUP.CONSULTANT) return;

    return (
      <td>{transformHelper.formatMoney(item?.psm)}</td>
    );
  }

  const renderExpiredDateData = (item) => {
    if (customer?.userGroup?.name === USER_GROUP.TENANT || authUser?.userGroup?.name === USER_GROUP.CONSULTANT) return;

    return (
      <td>{formatExpiryDate(item?.expiryDate)}</td>
    );
  }

  const renderLikedData = (item) => {
    if (!customer || customer?.userGroup?.name === USER_GROUP.INVESTOR) return;

    if (getUnitIds().includes(item.id)) {
      return (
        <td>
          <span className='btn-item'>
            <svg width="30" height="29" viewBox="0 0 30 29" fill="#000000" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.94007 28.6091C6.51873 28.6056 6.10927 28.4691 5.77009 28.2191C5.47194 28.0003 5.24027 27.7033 5.10081 27.3608C4.96135 27.0183 4.91955 26.6438 4.98008 26.2791L6.33009 18.399L0.610086 12.8191C0.351271 12.5636 0.167021 12.2424 0.0771578 11.89C-0.0127049 11.5376 -0.00478805 11.1673 0.100077 10.8191C0.21679 10.4669 0.429211 10.154 0.713541 9.91559C0.997871 9.67718 1.34291 9.52258 1.71009 9.46906L9.6201 8.31909L13.1501 1.14905C13.3157 0.815835 13.5709 0.535447 13.8871 0.339355C14.2033 0.143264 14.568 0.0392599 14.9401 0.0390625C15.3122 0.0392599 15.6768 0.143264 15.9931 0.339355C16.3093 0.535447 16.5645 0.815835 16.7301 1.14905L20.2601 8.31909L28.1701 9.46906C28.5373 9.52258 28.8823 9.67718 29.1666 9.91559C29.451 10.154 29.6634 10.4669 29.7801 10.8191C29.8849 11.1673 29.8929 11.5376 29.803 11.89C29.7132 12.2424 29.5289 12.5636 29.2701 12.8191L23.5501 18.399L24.9001 26.2791C24.9606 26.6438 24.9188 27.0183 24.7794 27.3608C24.6399 27.7033 24.4082 28.0003 24.1101 28.2191C23.8096 28.4384 23.4538 28.5696 23.0829 28.5979C22.7119 28.6262 22.3404 28.5504 22.0101 28.3791L14.9401 24.6591L7.8701 28.3791C7.58209 28.5267 7.2637 28.6054 6.94007 28.6091Z" fill="black" />
            </svg>
          </span>
        </td>
      )
    }

    return (
      <td>
        <span className="btn-item">
          <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.94007 28.6091C6.51873 28.6056 6.10927 28.4691 5.77009 28.2191C5.47194 28.0003 5.24027 27.7033 5.10081 27.3608C4.96135 27.0183 4.91955 26.6438 4.98008 26.2791L6.33009 18.399L0.610086 12.8191C0.351271 12.5636 0.167021 12.2424 0.0771578 11.89C-0.0127049 11.5376 -0.00478805 11.1673 0.100077 10.8191C0.21679 10.4669 0.429211 10.154 0.713541 9.91559C0.997871 9.67718 1.34291 9.52258 1.71009 9.46906L9.6201 8.31909L13.1501 1.14905C13.3157 0.815835 13.5709 0.535447 13.8871 0.339355C14.2033 0.143264 14.568 0.0392599 14.9401 0.0390625C15.3122 0.0392599 15.6768 0.143264 15.9931 0.339355C16.3093 0.535447 16.5645 0.815835 16.7301 1.14905L20.2601 8.31909L28.1701 9.46906C28.5373 9.52258 28.8823 9.67718 29.1666 9.91559C29.451 10.154 29.6634 10.4669 29.7801 10.8191C29.8849 11.1673 29.8929 11.5376 29.803 11.89C29.7132 12.2424 29.5289 12.5636 29.2701 12.8191L23.5501 18.399L24.9001 26.2791C24.9606 26.6438 24.9188 27.0183 24.7794 27.3608C24.6399 27.7033 24.4082 28.0003 24.1101 28.2191C23.8096 28.4384 23.4538 28.5696 23.0829 28.5979C22.7119 28.6262 22.3404 28.5504 22.0101 28.3791L14.9401 24.6591L7.8701 28.3791C7.58209 28.5267 7.2637 28.6054 6.94007 28.6091V28.6091ZM14.9401 1.98907L11.4201 9.16907C11.2766 9.45834 11.0652 9.70856 10.804 9.89838C10.5428 10.0882 10.2395 10.212 9.92008 10.2591L2.01008 11.4091L7.74009 16.9691C7.97133 17.1959 8.14401 17.4755 8.2433 17.7838C8.34258 18.0921 8.36552 18.4199 8.3101 18.7391L6.96009 26.6091L14.0201 22.889C14.3057 22.7401 14.623 22.6623 14.9451 22.6623C15.2672 22.6623 15.5845 22.7401 15.8701 22.889L22.9501 26.6191L21.5901 18.7491C21.5347 18.43 21.5576 18.1021 21.6569 17.7938C21.7562 17.4855 21.9288 17.2059 22.1601 16.9791L27.8901 11.399L19.9801 10.269C19.6606 10.2219 19.3574 10.0982 19.0961 9.90839C18.8349 9.71857 18.6235 9.46835 18.4801 9.17908L14.9401 2.00909V1.98907Z" fill="white"/>
          </svg>
        </span>
      </td>
    );
  }

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <h2 className="card-title underline-sm">
          PRECINCT DETAILS
        </h2>
        <span className="close" role="button" onClick={() => onClosePrecinct()}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM14.707 8.707C14.8892 8.5184 14.99 8.2658 14.9877 8.0036C14.9854 7.7414 14.8802 7.49059 14.6948 7.30518C14.5094 7.11977 14.2586 7.0146 13.9964 7.01233C13.7342 7.01005 13.4816 7.11084 13.293 7.293L11 9.586L8.707 7.293C8.61475 7.19749 8.50441 7.12131 8.3824 7.0689C8.2604 7.01649 8.12918 6.9889 7.9964 6.98775C7.86362 6.9866 7.73194 7.0119 7.60905 7.06218C7.48615 7.11246 7.3745 7.18671 7.28061 7.28061C7.18671 7.3745 7.11246 7.48615 7.06218 7.60905C7.0119 7.73194 6.9866 7.86362 6.98775 7.9964C6.9889 8.12918 7.01649 8.2604 7.0689 8.3824C7.12131 8.50441 7.19749 8.61475 7.293 8.707L9.586 11L7.293 13.293C7.19749 13.3852 7.12131 13.4956 7.0689 13.6176C7.01649 13.7396 6.9889 13.8708 6.98775 14.0036C6.9866 14.1364 7.0119 14.2681 7.06218 14.391C7.11246 14.5139 7.18671 14.6255 7.28061 14.7194C7.3745 14.8133 7.48615 14.8875 7.60905 14.9378C7.73194 14.9881 7.86362 15.0134 7.9964 15.0123C8.12918 15.0111 8.2604 14.9835 8.3824 14.9311C8.50441 14.8787 8.61475 14.8025 8.707 14.707L11 12.414L13.293 14.707C13.4816 14.8892 13.7342 14.99 13.9964 14.9877C14.2586 14.9854 14.5094 14.8802 14.6948 14.6948C14.8802 14.5094 14.9854 14.2586 14.9877 13.9964C14.99 13.7342 14.8892 13.4816 14.707 13.293L12.414 11L14.707 8.707Z" fill="black" />
          </svg>
        </span>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-hover table-precinct-bordered">
            <thead>
              <tr>
                <th>LEVEL</th>
                <th>TENANCY</th>
                <th>TRADING NAME</th>
                <th>LEASE</th>
                <th>PRECINCT</th>
                <th>AREA sqm</th>
                {renderPsmHeader()}
                {renderExpiredDateHeader()}
                {renderLikedHeader()}
              </tr>
            </thead>
            <tbody>
              {
                units.map((item, index) => (
                  <tr key={`row-${index}`} className={selectedUnit?.id === item.id ? 'active' : ''} onClick={() => onSelectUnit(item.id)}>
                    <td>{item?.level}</td>
                    <td>{item?.tenancy}</td>
                    <td>{item?.tradingName}</td>
                    <td>{item?.leaseType}</td>
                    <td>{item?.precinct}</td>
                    <td>{transformHelper.formatMoney(item?.areaSqm)}</td>
                    {renderPsmData(item)}
                    {renderExpiredDateData(item)}
                    {renderLikedData(item)}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};
export default ListPrecinct;
