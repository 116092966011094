import React, { useEffect, useState } from 'react';
import ListPrecinct from './_list-precinct';
import PrecinctFilters from './_precinct-filters';
import PrecinctDetail from './_precinct-detail';
// import ListPrecinctCollapsed from './_list-precinct-collapsed';
import './index.scss';
import { useDispatch } from 'react-redux';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import { useSelector } from 'react-redux';
import {GROSS_RENT_OPTION, AREA_OPTION, } from '../../constants/options';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { USER_GROUP } from '../../constants/master-data';

const PrecinctExplore = () => {
  const dispatch = useDispatch();
  const activeAreaId = useSelector((state) => state.home.activeAreaId);
  const filterUnitArea = useSelector((state) => state.unitExplore.filterUnitArea);
  const filterUnitGrossRent = useSelector((state) => state.unitExplore.filterUnitGrossRent);
  const filterUnitCentre = useSelector((state) => state.unitExplore.filterUnitCentre);
  const filterUnitLevel = useSelector((state) => state.unitExplore.filterUnitLevel);
  const filterUnitPrecinct = useSelector((state) => state.unitExplore.filterUnitPrecinct);
  const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
  const isShowPrecinctDetail = useSelector((state) => state.unitExplore.isShowPrecinctDetail);
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);

  useEffect(() => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  }, [unitQuery]);

  useEffect(() => {
    onFilterChange();
  }, [filterUnitArea, filterUnitGrossRent, filterUnitCentre, filterUnitLevel, filterUnitGrossRent, filterUnitPrecinct])

  const onFilterChange = () => {
    const query = {};

    if (activeAreaId) {
      query['area[equalId]'] = activeAreaId;
    }

    if (filterUnitCentre) {
      query['centre[equal]'] = filterUnitCentre;
    }

    if (filterUnitLevel) {
      query['level[equal]'] = filterUnitLevel;
    }

    if (filterUnitArea) {
      const areaData = AREA_OPTION.find((item) => item.id === filterUnitArea);
      areaData.min && (query['areaSqm[gte]'] = areaData.min);
      areaData.max && (query['areaSqm[lte]'] = areaData.max);
    }

    if (filterUnitGrossRent) {
      const grossRentData = GROSS_RENT_OPTION.find((item) => item.id === filterUnitGrossRent);
      grossRentData.min && (query['psm[gte]'] = grossRentData.min);
      grossRentData.max && (query['psm[lte]'] = grossRentData.max);
    }

    if (filterUnitPrecinct) {
      query['precinct[equal]'] = filterUnitPrecinct;
    }

    dispatch(unitExploreAct.reqSetUnitQuery(query));
    isFiltered() && resetUnitDetail();
  }

  const resetUnitDetail = () => {
    dispatch(unitExploreAct.reqSetSelectedUnit(''));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowUnitDetail(true));
  };

  const isFiltered = () => {
    return filterUnitCentre || filterUnitLevel || filterUnitArea || filterUnitGrossRent;
  }

  const renderPrecinctDetail = () => {
    if (!isShowPrecinctDetail) return;

    return (
      <div className={`col-auto h-100 overflow-auto`}>
        <PrecinctDetail/>
      </div>
    )
  }

  const renderPrecinctFilter = () => {
    if (isShowPrecinctDetail) {
      return;
    }

    return (
      <div className="col-auto h-100 overflow-auto">
        <PrecinctFilters/>
      </div>
    )
  }

  const renderListPrecinct = () => {
    if (isShowFloorplan) {
      return (
        <>
          {/* <div className="col-auto h-100 overflow-auto">
            <ListPrecinctCollapsed/>
          </div> */}
          <div className="col h-100 overflow-auto" />
        </>
      )
    }

    return (
      <>
        {renderPrecinctFilter()}
        <div className="col h-100 overflow-auto">
          <ListPrecinct/>
        </div>
      </>
    )
  }

  return (
    <div className="container-fluid container-absolute">
      <div className="row wrapper-modal">
        {renderListPrecinct()}
        {renderPrecinctDetail()}
      </div>
    </div>
  )
}

const PrecinctExplorePanel = () => {

  const isShowPrecinctExploreDetail = useSelector((state) => state.precinctExplore.isShowPrecinctExploreDetail);

  return (
    <TransitionGroup>
      {isShowPrecinctExploreDetail && (
      <CSSTransition timeout={1000} classNames="fade-item">
        <PrecinctExplore />
      </CSSTransition>
      )}
    </TransitionGroup>
  )
};

export default PrecinctExplorePanel;
