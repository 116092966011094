import React, { useState } from "react";
import { FormControl, InputGroup, Modal, Form } from "react-bootstrap";
import "./index.scss";
import * as yup from "yup";
import customerApi from "../../apis/api/customer";
import { useDispatch } from "react-redux";
import { actAddOneCustomer } from '../../reduxs/cms/action';
import { toast } from "react-toastify";

const AddNewCustomerModal = (props) => {
  const { show, setIsShowAddNewCustomerModal } = props;
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [mobile, setMobile] = useState('');
  const [userGroup, setUserGroup] = useState('610a4a3351400773f94bfa89');
  const [error, setError] = useState();
  const notify = (message) => toast.info(message);

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
    company: yup.string().required(),
    role: yup.string().required(),
    mobile: yup.string().required(),
    userGroup: yup.string().required(),
  });

  const handleCreateCustomer = async () => {
    try {
      const data = {
        userGroup: userGroup,
        email: emailAddress,
        name: name,
        company: company,
        role: role,
        mobile: mobile,
      };
      const result = await validationSchema.validate(data);
      const res = await customerApi.createCustomer(result);
      if (res.data) {
        const newCustomer = {
          ...res.data,
          userGroup: {
            id: res.userGroup,
            name: res.userGroup === '610a4a3351400773f94bfa89' ? 'tenant' : 'investor'
          }
        };
        notify("Customer created successfully!");
        dispatch(actAddOneCustomer(newCustomer));
        handleClose();
      }
    } catch (err) {
      setError(err);
      toast.error(err.message);
    }
  };
  const handleClose = () => {
    setIsShowAddNewCustomerModal(false);
    handleResetData();
  };

  const handleResetData = () => {
    setEmailAddress("");
    setName("");
    setCompany("");
    setRole("");
    setMobile("");
  };
  return (
    <Modal
      className="wrap-create-user-profile-modal"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        <div className="modal-form__title underline-sm mb-4">
          create a new customer
        </div>
        <p className="mb-4">Create a new profile</p>
        <div className="mb-4">
          <InputGroup className="form-group">
            <FormControl
              id="email"
              name="email"
              type="email"
              value={emailAddress}
              className="form-control"
              placeholder="Email Address"
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="name"
              name="name"
              value={name}
              className="form-control"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="company"
              name="company"
              value={company}
              className="form-control"
              placeholder="Company Name"
              onChange={(e) => setCompany(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="role"
              name="role"
              value={role}
              className="form-control"
              placeholder="Role"
              onChange={(e) => setRole(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="form-group">
            <FormControl
              id="mobile"
              name="mobile"
              value={mobile}
              className="form-control"
              placeholder="Phone Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </InputGroup>
          <div className="mt-3 d-flex">
            <span className="label">User Type:</span>
            <select
              className="ms-2"
              name="userGroup"
              id="userGroup"
              style={selectStyle}
              value={userGroup}
              onChange={(e) => setUserGroup(e.target.value)}
            >
              <option value="610a4a3351400773f94bfa89">Tenant</option>
              <option value="606d6f356af09f50f367ee23">Investor</option>
              <option value="63030d2f94bc7bf551da79e3">Consultant</option>
            </select>
          </div>

        </div>
        <div className="wrap-button d-flex justify-content-between">
          <button onClick={handleClose}>Cancel</button>
          <button onClick={handleCreateCustomer}>Save</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const selectStyle = {
  border: 'unset',
  borderBottom: '1px solid',
  outline: 'unset'
}

export default AddNewCustomerModal;
