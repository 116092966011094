import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMediaUrl } from '../../helper/media';
import { useSelector } from 'react-redux';

const FloorPlanGallery = (props) => {
  const { floorPlanGalleryRef } = props;
  const [media, setMedia] = useState([]);
  const refs = useRef({});
  const [activeSlide, setActiveSlide] = useState(0);
  const [zoomSize, setZoomSize] = useState(60);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    className: 'slides',
    focusOnSelect: true,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    afterChange: (index) => {
      setZoomSize(60);
      refs.current[activeSlide].style.backgroundSize = `60%`;
      refs.current[index].style.backgroundSize = `60%`;
      setActiveSlide(index);
    }
  };

  useEffect(async () => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floor_plan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
    }
  }, [selectedUnit]);

  const zoomIn = () => {
    refs.current[activeSlide].style.backgroundSize = `${zoomSize + 10}%`;
    setZoomSize(zoomSize + 10);
  }

  const zoomOut = () => {
    if (zoomSize > 10) {
      refs.current[activeSlide].style.backgroundSize = `${zoomSize - 10}%`;
      setZoomSize(zoomSize - 10);
    }
  }

  return (
    <>
      <div className="wrap-floor-plan-gallery">
        <Slider {...settings} ref={floorPlanGalleryRef} className="slider-floorplan">
          {media.map((item, key) => {
            return (
              <div key={key}>
                <div
                  ref={(ref) => (refs.current[key] = ref)}
                  style={{ backgroundImage: `url(${getMediaUrl(item.path)})` }}
                  className="floorplan-item"
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="wrap-zoom-btn">
        <img onClick={() => zoomOut()} className="me-4" src="/icons/minus.svg" alt="" />
        <img onClick={() => zoomIn()} src="/icons/plus.svg" alt="" />
      </div>
    </>
  );
};

export default FloorPlanGallery;
