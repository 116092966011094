import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import './index.scss';

const VideoIntro = () => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();

  const onSkipVideo = () => {
    dispatch(reqSetIsShowReplayVideo(false));
  }

  return (
    <div className="wrap-video-intro">
      <video
        ref={videoRef}
        autoPlay={true}
        onEnded={() => startDiscover()}
        preload="auto"
        id="intro-video-2"
        style={{ backgroundImage: `url("/images/video_bg.png")` }}
      >
        <source src="/uploads/videos/ASH2239_TheDistrict_OfflineEdit_ID_02.mov" type="video/mp4" />

      </video>
      <button onClick={() => onSkipVideo()} className="btn-skip-video btn btn-light">SKIP VIDEO</button>
    </div>
  );
}

export default VideoIntro;
