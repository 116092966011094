import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import parse from "html-react-parser";
import {
  reqSetActiveAreaId,
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
} from "../../reduxs/home/action";
import { PAGES } from "../../constants/options";
import { reqFilterUnitPrecinct } from "../../reduxs/unit-explore/action";
import { reqSetIsShowPrecinctExploreDetail } from "../../reduxs/precinct-explore/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import {
  reqSetActiveGalleryModal,
  reqSetIsShowGalleryModal,
} from "../../reduxs/district-future-detail/action";
import GalleryDetail from "../gallery/_gallery-detail";
import { USER_GROUP } from "../../constants/master-data";

const RightPanel = (props) => {
  const { handleClickTransportOptions, page } = props;
  const isShowRightPanel = useSelector(
    (state) => state.exploreModal.isShowExploreModal
  );
  const modal = useSelector((state) => state.exploreModal.modal);
  const dispatch = useDispatch();
  const isShowGallery = useSelector(
    (state) => state.districtdetail.isShowGalleryModal
  );
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);

  const onClose = () => {
    handleClickTransportOptions([], {});
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetActivePrecinctID());
    dispatch(reqSetIsShowExploreModal(false));
  };

  const handleButtonInContent = (event) => {
    const clickedEl = event.target;

    if (clickedEl.id == "btn_close_right_panel") {
      dispatch(reqSetIsShowGalleryModal(false));
      return onClose();
    }

    if (clickedEl.id == "btn_explore_precinct") {
      if (clickedEl.dataset?.area_id) {
        dispatch(reqSetActiveAreaId(clickedEl.dataset.area_id));
      } else {
        dispatch(reqSetActiveAreaId());
      }

      if (clickedEl.dataset?.precinct) {
        dispatch(reqFilterUnitPrecinct(clickedEl.dataset.precinct));
      } else {
        dispatch(reqFilterUnitPrecinct());
      }

      dispatch(reqSetIsShowPrecinctExploreDetail(true));
      onClose();
    }

    if (clickedEl.id === "btn_view_gallery") {
      if (clickedEl.innerHTML?.toLowerCase() === "view gallery") {
        clickedEl.innerHTML = "CLOSE GALLERY";
        dispatch(reqSetActiveGalleryModal(clickedEl.dataset?.gallery_id));
        dispatch(reqSetIsShowGalleryModal(true));
      } else {
        clickedEl.innerHTML = "VIEW GALLERY";
        dispatch(reqSetActiveGalleryModal());
        dispatch(reqSetIsShowGalleryModal(false));
      }
    }
  };

  const renderModalContent = () => {
    if (customer?.userGroup?.name === USER_GROUP.INVESTOR || authUser?.userGroup?.name === USER_GROUP.INVESTOR) {
      return parse(modal?.content || "");
    }

    return parse(modal?.content || "", {
      replace: domNode => {
        if (domNode.attribs?.id === "investor-details") return <></>;
      }
    });
  }

  return (
    <>
      <TransitionGroup>
        {isShowRightPanel && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              className={`wrap-right-panel ${page === PAGES.EXPLORE_PRECINCT_PAGE && "above-timeline"
                }`}
              onClick={(e) => handleButtonInContent(e)}
            >
              {renderModalContent()}
            </div>
          </CSSTransition>
        )}
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              className={`wrap-gallery-modal`}
              onClick={(e) => handleButtonInContent(e)}
            >
              <GalleryDetail />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default RightPanel;
